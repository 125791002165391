/*
//
// TOTAC STYLES
// by Digital Kids
// http://digitalkids.fr
//
*/

@import url('https://fonts.googleapis.com/css?family=Lora:400,400italic,700|Open+Sans:300,400,700|Roboto:400,600');

// VARIABLES
$break-xs: 551px;
$break-sm: 768px; 
$yellow: #f9c544;


/*
// GLOBALS AND CONTAINERS
*/

body { 
	max-width: 1280px; padding: 0 3%; margin: 0 auto; 
	@media(max-width: $break-sm) { padding: 0; }
}

img { max-width: 100%; height: auto;}

a:hover, a:focus { text-decoration: none; outline: none; box-shadow: none; }

/*
// COMMON STYLES
*/

#main {
	section {
		&.overlay { 
			&:before { content: ''; background-color: rgba(0,0,0,0.4); position: absolute; width: 100%; height: 100%; top: 0; bottom: 0; left: 0; right: 0; }
		}
		&.pattern-grey { background-image: url('../img/grey-pattern.jpg'); background-size: cover; }
		&.background-grey { 
			background-color: #666; color: #fff; 
			a { color: #fff; }
		}
		&.background-grey-light { background-color: #E7E7E7; }
	}
	
}


/*
// NAVBAR
*/

.navbar { 
	border-radius: 0; background-color: #fff; border: 0; margin-bottom: 0; padding: 8px 0; 
	.navbar-collapse { padding: 0; }
	.navbar-brand {
		padding: 8px 15px; position: absolute; width: 162; left: calc(50% - 81px); top: 8px;
		img { max-height: 34px; }
	}
	.navbar-left { 
		margin-left: -15px; text-transform: uppercase; font-family: 'Lora'; 
		a {
			color: #000 !important;
			&:hover { color: $yellow !important; }
			&.active { font-weight: bolder !important; color: #000 !important; }
			&.active:hover { color: #000; }
		}
	}
	.navbar-right { 
		font-family: Lora; 
		.icon-key { display:inline-block; font-size: 14px; margin-right: 15px; -webkit-transform: scale(1.4); transform: scale(1.4); }
		a { 
			color: #000 !important; 
			&:hover { color: $yellow !important; }
		}
	}
}

.mini-menu {
	a {
		&:hover .home-link-content { text-shadow: 0px 0px 3px rgba(0,0,0,0.12); color: $yellow; }
		&.active {
			.home-link-content { text-shadow: 0px 0px 3px rgba(0,0,0,0.12); color: $yellow; }
			.home-link { 
				-webkit-filter: grayscale(0%); filter: grayscale(0%); cursor: pointer; 
				&.overlay:before { background-color: rgba(0,0,0,0.25) !important; }
			}
		}
	}
	.home-link { 
		background-position: center center; height: 60px;
		-webkit-filter: grayscale(100%); filter: grayscale(100%);
		&:hover { -webkit-filter: grayscale(0%); filter: grayscale(0%); cursor: pointer; }
		&.overlay {
			&:before { 
				content: ''; background-color: rgba(0,0,0,0.4); position: absolute; width: 100%; height: 100%; top: 0; bottom: 0; left: 0; right: 0; z-index: 0; 
				-webkit-transition:all 0.2s ease-in-out;-moz-transition:all 0.2s ease-in-out;-ms-transition:all 0.2s ease-in-out; transition:all 0.2s ease-in-out;
			}
			&:hover { 
				&:before { background-color: rgba(0,0,0,0.25) !important; } 
			}
		}
		&.home-link-presentation { background-image: url('../img/home-link-presentation.jpg');  }
		&.home-link-make-test { background-image: url('../img/home-link-make-test.jpg'); }
		&.home-link-prepare { background-image: url('../img/home-link-prepare.jpg'); }
		&.home-link-contact { background-image: url('../img/home-link-contact.jpg'); }
		.home-link-content { 
			position: absolute; width: 100%; height: 100%; left: 0; top: 0; bottom: 0; right: 0; color: #fff; z-index: 1; text-align: center; line-height: 36px;
			font-family: Lora; font-weight: bold; font-size: 16px; padding: 30px; 
			.thumb {
				display: inline-block; vertical-align: middle;
				i { font-size: 34px; margin-right: 15px; display: inline-block; line-height: 1;}
			}
		}
	}

}


/*
// LOGIN MODAL
*/

#loginModal {
	.modal-content { 
		background: #eee; border-radius: 0;
		.modal-header { border: none; }
		.modal-body { 
			padding: 15px 60px 0px 60px; 
			h3 { margin-top: 0; margin-bottom: 30px; font-family: Lora; font-weight: bold; font-size: 22px; }
			.form-register:before { height: 341px; border-left: 1px solid #666; content: ""; position: absolute; top: 55px; left: 0px; }
			.form-group { 
				margin-bottom: 10px; font-family: Lora;
				input, textarea, select { border: 0; }
			}
			.help-block { text-transform: uppercase; font-size: 11px; }
			.submit-register, .submit-login { margin-top: 20px; }
			.forgot-pass { color: #666666; font-family: Lora;}
			.birthdate.form-control[readonly] { background: #fff !important;}
		}
		.modal-footer { 
			text-align: center; font-family: Lora; font-weight: 600; font-size: 18px; border-top: 0; padding-bottom: 45px;
			.icon-chart { max-height: 28px; margin-right: 15px; }
			span { display: inline-block; border-bottom: 2px solid #000; padding: 2px 0; }
		}
	}
}


/*
// LOGIN PAGE
*/

section {
	&.login-forms {
		padding: 60px 15%; font-family: Lora; 
		h3 { font-weight: bold; margin-bottom: 30px; } 
	}
}

/*
// FORGOT PASSWORD
*/

section {
	&.forgot-password { 
		padding: 30px 25%; 
		h1 { font-size: 28px; font-family: Lora; font-weight: bolder; margin-bottom: 45px; }
	}
	&.reset-password {
		@extend .forgot-password;
	}
}

/*
// HOME
*/

.home {
	.section-wrapper {
		position:relative; 
		.image-label { 
			position: absolute; max-width: 140px; top: 45px; left: 45px; -webkit-filter: grayscale(0%) !important; filter: grayscale(0%) !important; z-index: 2;
			@media(max-width: $break-sm) { left:30px; top:30px; max-width: 80px; }
		}
	}
	section {
		&.make-test {
			position: relative; width: 100%; background-image: url('../img/home-make-test.jpg'); background-size: cover; margin-bottom: 5px; height: calc(100vh - 66px - 195px);
			max-height: 620px; min-height: 400px; 
			-webkit-filter: grayscale(100%); filter: grayscale(100%);
			&:hover { -webkit-filter: grayscale(0%); filter: grayscale(0%); cursor: pointer; }
			.make-test-wrapper { 
				position: absolute; height: 100%; width: 100%; text-align: center; padding: 90px 25%; color: #fff;
				h1 { text-transform: uppercase; line-height: 1.4; font-family: 'Open Sans'; font-weight: bold; }
				p { font-size: 24px; font-family: Lora; margin-bottom: 45px; }
				a { 
					color: #fff; 
					&.make-test-link { display: inline-block; border-bottom: 2px solid #fff; font-size: 24px; font-family: Lora; margin-top: 5px; } 
				}
				.icon-go { 
					&:hover { color: $yellow; }
					font-size: 38px; line-height: 1;
				}
				@media (max-width: $break-sm) {
					padding: 30px 15%;
					h1 { font-size: 24px; }
					p { font-size: 18px; margin-bottom: 45px; }
					a { font-size: 18px; margin-top: 5px; }
				}
			}
		}
		&.home-links {
			.home-link { 
				width: 100%; position: relative; padding-bottom: 100%; background-size: cover; background-position: center center; 
				-webkit-filter: grayscale(100%); filter: grayscale(100%);
				&:hover { 
					-webkit-filter: grayscale(0%); filter: grayscale(0%); cursor: pointer; 
					.home-link-content > div { border-color: #dfdfdf; }
				}
				&.overlay {
					&:before { 
						content: ''; background-color: rgba(0,0,0,0.4); position: absolute; width: 100%; height: 100%; top: 0; bottom: 0; left: 0; right: 0; z-index: 0; 
						-webkit-transition:all 0.2s ease-in-out;-moz-transition:all 0.2s ease-in-out;-ms-transition:all 0.2s ease-in-out; transition:all 0.2s ease-in-out;
					}
					&:hover { 
						&:before { background-color: rgba(0,0,0,0) !important; } 
					}
				}
				&.home-link-presentation { background-image: url('../img/home-link-presentation.jpg');  }
				&.home-link-make-test { background-image: url('../img/home-link-make-test.jpg'); }
				&.home-link-prepare { background-image: url('../img/home-link-prepare.jpg'); }
				&.home-link-contact { background-image: url('../img/home-link-contact.jpg'); }
				.home-link-content { 
					position: absolute; width: 100%; height: 100%; left: 0; top: 0; bottom: 0; right: 0; color: #fff; z-index: 1; text-align: center; line-height: 1.2;
					font-family: Lora; font-weight: bold; font-size: 18px; padding: 30px;
					> div { 
						padding: 30px; border:1px solid transparent; width: 100%; 
						@media (max-width: $break-sm) { padding: 15px; }
					}
					.thumb {
						img { 
							max-width: 64px; margin-top: 15px; 
							@media (max-width: $break-sm) { max-width: 44px; }
						}
					}
					.icon { 
						font-size: 28px; margin-top: 30px; 
						i {
							&:hover { color: $yellow; }
						}
						@media (max-width: $break-sm) { display: none; }
					}
					@media (max-width: $break-sm) { padding: 15px; font-size: 15px; }
				}
			}
		}
	}
	.block-verif-degree { 
		padding: 75px 30%; font-family: Lora;
		h4 { margin-top: 0; font-weight: bold; font-size: 20px; }
		.form-verif-degree { 
			margin-top: 30px; 
			.form-group { 
				margin: 0; 
				input.form-control { border: 0; padding: 7px 12px; height: 36px; }
				.btn-grey { line-height: 22px; }
				.help-block { font-size: 11px; }
			}
		}
		@media (max-width: $break-sm) { padding: 45px 15px; }
	}
}


/*
// QUESTIONNAIRE 
*/

.test-landing {
	.intro-text {
		padding: 60px 10%; font-family: Lora; font-size: 16px; 
		h4 { 
			margin-top: 0; font-weight: bold; font-size: 22px;
		}
		.presentation-intro-link {
			a.link-make-test { font-weight: bold; color: #333; border-bottom: 2px solid #333; padding-bottom: 2px; display: inline-block; margin-bottom: 10px; }
			i { font-size: 26px; display: inline-block; line-height: 36px; vertical-align: middle; margin-left: 15px;}
			a.link-make-test-arrow { border: 0; color: #333; }
		}
	}
	.explanations {
		text-align: center; max-width: 980px; padding: 0px 0px 60px 0; font-family: Lora; font-weight: bold; font-size: 16px; margin: 0 auto;
		img { display: block; max-height: 52px; margin: 0 auto 15px auto;}
	}
	.presentation-intro-link {
		font-size: 22px; font-family: Lora;
		a.link-make-test { font-weight: bold; color: #333; border-bottom: 2px solid #333; padding-bottom: 2px; display: inline-block; margin-bottom: 50px; }
		i { font-size: 26px; display: inline-block; line-height: 36px; vertical-align: middle; margin-left: 15px;}
		a.link-make-test-arrow { border: 0; color: #333; }
	}
}

.test-exam {
	.test-clock {
		.timer { width: 60px; }
		* { display: inline-block; }
		img { max-width: 30px; margin-right: 5px; }
		a { padding-top: 10px; padding-bottom: 10px; }
	}
	section { 
		background-color: #F7F7F7; padding: 4%; padding-bottom: 30px; font-family: Lora;
		.questions { background: #fff; padding: 15px; }
		.btn-next, .get-results { margin-top: 30px; }
	}
	.question { 
		display: none; 
		&.active { display: block; }
		a {
			&.selected { font-weight: bolder; }
		}
		h2 { font-size: 15px; margin-bottom: 15px; padding: 15px; }
		hr { margin: 15px 0; }
		ol { list-style: none outside; }
		.vjs-afterglow-skin.vjs-responsive { margin-bottom: 30px; }
		video { max-width: 100%; height: auto; }
		::cue { color:#ccc; font-size: 26px; }
	}	
}



.test-results {
	section { 
		padding: 60px 5%; font-family: Lora;
		.intro-text { 
			margin: 0 auto; margin-bottom: 30px; max-width: 880px;
			h4 { font-weight: bold; margin-top: 0; font-size: 22px;}
		}
		.score-container {
			margin-bottom: 30px;
			h3 { margin-top: 0; font-size: 22px; font-weight: bold; }
			.result-score { 
				font-size: 34px; border: 2px solid #fff; padding: 60px; position: relative;
				&:before { 
					content: ""; position: absolute; width: 50px; height: 50px; background-image: url('../img/ribbon.png'); bottom: -33px; left: calc(50% - 25px); 
					background-size: contain; background-repeat: no-repeat;
				}
			}
		}
		.score-categories { 
			text-transform: uppercase; font-family: 'Open Sans';
			ul { list-style: none outside; padding: 0; font-weight: bold;  }
		}
		.make-test-ranks-wrapper {
			padding: 45px 30px 45px 30px; background: #fff; margin-bottom: 0px; margin-top: 45px;
			.make-test-ranks-content {
				border: 1px solid #1D1D1D; padding: 20px; text-align: center; position: relative; 
				&:before { 
					content: ""; position: absolute; width: 40px; height: 40px; background-image: url('../img/ribbon.png'); bottom: -25px; left: calc(50% - 20px); 
					background-position: center center; background-size: contain; background-repeat: no-repeat;
				}
				h3 { margin-top: 0; margin-bottom: 10px; font-weight: bold; display: inline-block; background: #fff; 
					position: absolute; top: -15px; width: 240px; left: calc(50% - 120px) }
				hr { width: 80px; margin: 0 auto 30px auto; border-color: #b3b3b3; }
				ul { 
					li { text-align: center; margin: 0 2%;}
					img { display: block; max-width: 38px; margin: 0 auto 8px auto; }
					margin: 15px 0; font-family: 'Open Sans'; font-weight: 300; color: #1d1d1b; font-size: 16px;
					strong { color: #1A1A1A; display: block; }
				}
			}
		}
	}
	.chart { margin: 0 auto; width: auto; margin: 0 auto; clear: both; margin-bottom: 60px; margin-top: 90px; max-width: 840px; border-bottom: 5px double #1d1d1b; padding-bottom: 30px; }
	.bar { width: calc(100% / 6 - 60px); float: left; background: #575756; height: 340px; margin: 0 30px; display: flex; align-items: flex-end; position: relative; border-radius: 5px; }
	.bar .caption { position: absolute; transform: rotate(-90deg); left: calc(-10%); transform-origin: left bottom 0; text-transform: uppercase; white-space: nowrap; font-weight: bold; font-family: Roboto; bottom: 0; }
	.bar .category-progress { background: #f7cb69; width: 100%; display: inline-block; position: relative;}
	.bar .category-progress .bubble { width: 120%; padding-bottom: 120%; border-radius: 50%; background: white; display: block; position: absolute; margin-left: calc(-10%); margin-top: -60%; border: 2px solid #575756; z-index: 2; border-bottom-color: #f7cb69; position: relative; }
	.bar .category-progress .bubble span { display:block; position: absolute; width: 100%; text-align: center; vertical-align: middle; margin-top: calc(50% - 15px); line-height: 30px; font-size: 22px;font-weight: bold; }
	.final-result { font-family: Roboto; text-transform: uppercase; text-align: center; font-size: 22px; }
}


/*
// PAGES
*/

.page { 
	h1 { font-family: Lora; font-weight: bold; }
	section {
		&.presentation-intro { 
			padding: 60px 10%; font-family: Lora; font-size: 16px; 
			h4 { 
				margin-top: 0; font-weight: bold; font-size: 22px;
			}
			.presentation-intro-link {
				a.link-make-test { font-weight: bold; color: #333; border-bottom: 2px solid #333; padding-bottom: 2px; display: inline-block; margin-bottom: 10px; }
				i { font-size: 26px; display: inline-block; line-height: 36px; vertical-align: middle; margin-left: 15px;}
				a.link-make-test-arrow { border: 0; color: #333; }
			}
			@media (max-width: $break-sm) {
				padding: 30px; 
				h4 { font-size: 20px; margin-bottom: 15px;}
				p { font-size: 14px; }
			}
		}
		&.presentation-explications {
			padding: 90px 9.5%; text-align: center; font-family: Lora; 
			h4 { font-weight: bold; }
			hr { width: 35%; margin: 15px auto; border-top: 2px solid #333; } 
			img { max-width: 64px; margin-bottom: 15px;  }
			@media (max-width: $break-sm) {
				padding: 15px 45px; 
				.row > div { margin: 30px 0; }
			}
		}
		&.presentation-face {
			position: relative; font-family: Lora; font-weight: bold;
			.presentation-face-text-top { position: absolute; top: 45px; right: 45px; color: #fff; line-height: 1.2; font-size: 24px;  }
			.presentation-face-text-bottom { 
				position: absolute; bottom: 45px; right: 45px; color: #fff; line-height: 1.2; font-size: 20px; vertical-align: bottom;
				a { padding-bottom: 2px; border-bottom: 2px solid #fff; color: #fff; margin-bottom: 8px; display: inline-block; }
				i { vertical-align: middle; display: inline-block; font-size: 26px; margin-left: 10px; }
			}
		}
		&.make-test-intro {
			@extend .presentation-intro;
			padding: 60px 5%;
			.make-test-ranks-wrapper {
				padding: 45px 30px 45px 30px; background: #fff; margin-bottom: 45px;
				.make-test-ranks-content {
					border: 1px solid #1D1D1D; padding: 20px; text-align: center; position: relative; 
					&:before { 
						content: ""; position: absolute; width: 40px; height: 40px; background-image: url('../img/ribbon.png'); bottom: -25px; left: calc(50% - 20px); 
						background-position: center center; background-size: contain; background-repeat: no-repeat;
					}
					h3 { margin-top: 0; margin-bottom: 10px; font-weight: bold; display: inline-block; background: #fff; 
						position: absolute; top: -15px; width: 240px; left: calc(50% - 120px) }
					hr { width: 80px; margin: 0 auto 30px auto; border-color: #b3b3b3; }
					ul { 
						li { text-align: center; margin: 0 2%;}
						img { display: block; max-width: 38px; margin: 0 auto 8px auto; }
						margin: 15px 0; font-family: 'Open Sans'; font-weight: 300; color: #1d1d1b; font-size: 16px;
						strong { color: #1A1A1A; display: block; }
					}
				}
			}
			img { max-width: 100%; margin: 60px 0; }
			.presentation-intro-link { 
				margin: 30px 0 0 30px; 
				a { font-size: 22px; }
				i { font-size: 34px; vertical-align: middle; }
			}
		}
		&.preparation-intro {
			@extend .presentation-intro;
			padding: 60px; 
			.intro-text { max-width: 680px; margin: 0 auto; }
			.books {
				margin: 30px 0; font-family: Lora; text-align: center; font-size: 13px;
				.book {
					margin: 30px 0;
					.book-title { margin-bottom: 0px; }
					.book-image { 
						margin-bottom: 15px; 
						img { max-height: 190px; }
					}
					.book-author { font-weight: bold; }
				}
			}
		}
		&.page-contact { 
			padding: 30px 30%; margin: 0 auto; 
			h1 { text-align: center; margin-bottom: 30px; }
			form {
				font-family: Lora; 
				.form-control { box-shadow: 1px 1px 2px rgba(0,  0,  0, 0.1); border: 0; padding: 8px 12px; min-height: 38px; }
				.btn-grey { margin-top: 15px; }
			}
		}
		&.page-cgv {
			padding: 30px 15%;
			h1 { font-family: Lora; margin-bottom: 30px;}
			h3 { font-family: Lora; font-weight: bold;}
		}
	}
}


/*
// PROFILE
*/

.user-account {
	section {
		padding: 60px 20%; font-family: Lora;
		h4 { margin-top: 0; font-weight: bold; font-size: 22px; }
		form { 
			display: none;
			margin: 30px 0 0 0; 
			.form-control { box-shadow: 1px 1px 2px rgba(0,  0,  0, 0.1); border: 0; padding: 8px 12px; min-height: 38px; }
			.btn-grey { margin-top: 30px; }
		}
		&.payments {
			h4 { margin-bottom: 30px; }
		}
		&.results {
			h4 { margin-bottom: 30px; }
		}
	}
}


/*
// PAYMENT
*/

.payment-form {
	section { 
		padding: 60px 20%; 
		h1 { font-family: Lora; font-size: 32px; margin-bottom: 30px; font-weight: bold;}
		form {
			font-family: Lora;
			.checkbox { 
				margin-bottom: 30px; text-transform: uppercase;  
				
				label { font-weight: bold; }
			}
		}
		.payment-details {
			h3 { font-weight: bold; margin-top: 0; }
		}
	}
}

.payment-charge {
	section {
		padding: 30px; 
	}
}


/*
// CERTIFICATES
*/

.certificate-verification {
	section { 
		padding: 60px 25%;
	}
}


/*
// FOOTER
*/

#footer {
	background-color: #333333; padding: 20px; color: #fff; line-height: 24px; font-family: Lora;
	ul { margin: 0; }
	a { 
		color: #fff; 
		&:hover { color: $yellow; }
	}
	.footer-logo { max-height: 24px; } 
	.copyright { font-family: 'Open Sans';}
	@media (max-width: $break-sm) {
		text-align: center !important; 
		.footer-logo { margin-bottom: 15px; }
		.copyright { margin-top: 15px; }
	}
}

.btn-grey { 
	text-transform: uppercase; background-color: #666666; color: #fff; font-size: 12px; font-family: 'Open Sans'; padding: 6px 30px; border: 0; 
	&:hover { background-color: $yellow; color: #fff; }
}

.flex-center { display: flex; align-items: center; justify-content: center; }
.transition { -webkit-transition:all 0.2s ease-in-out;-moz-transition:all 0.2s ease-in-out;-ms-transition:all 0.2s ease-in-out; transition:all 0.2s ease-in-out; }

.alert { margin-bottom: 30px; border-radius: 0; }

.btn, .form-group input, .form-group textarea, .form-group select { 
	border-radius: 0; 
	&:focus { box-shadow: none; outline: none; }
}
.form-control:focus { border-color: $yellow; }
.form-group.has-error .form-control { border: 1px solid #a94442 !important; }
.row.gutters-xl { margin-right: -30px; margin-left: -30px; }
.row.gutters-xl > [class^="col-"], .row.gutters-xl > [class*=" col-"] { padding-right: 30px; padding-left: 30px; }
.row.gutters-light { margin-right: -8px; margin-left: -8px; }
.row.gutters-light > [class^="col-"], .row.gutters-light > [class*=" col-"] { padding-right: 8px; padding-left: 8px; }
.row.gutters-xs { margin-right: -5px; margin-left: -5px; }
.row.gutters-xs > [class^="col-"], .row.gutters-xs > [class*=" col-"] { padding-right: 5px; padding-left: 5px; }
.row.gutters-no { margin-right: 0px; margin-left: 0px; }
.row.gutters-no > [class^="col-"], .row.gutters-no > [class*=" col-"] { padding-right: 0px; padding-left: 0px; }

/*
// MEDIA QUERIES 
*/

@media (max-width:$break-sm) {
	.page {
		section.page-contact { 
			padding: 30px; 
			h1 { font-size: 28px; }
		}
	}
	.mini-menu {
		.home-link { 
			.home-link-content { 
				font-size: 13px; padding: 10px;
				.thumb {
					img { max-width: 24px; margin-right: 10px; }
				}
			}
		}
		.thumb { display:inline-block; 
			img { max-height: 34px; }
		}
	}
}